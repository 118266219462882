.channel-content {
    /* display: flex;
    flex-direction: column; */
    margin-top: -25px;
    width: 745px;
}

.channels-masthead {
    width: 745px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.channels-masthead__tabs {
    margin-left: 25px;
    margin-bottom: -1px;
    z-index: 100;

    ul {
        display: flex;
        flex-direction: row;
    }

    li {
        padding: 4px 20px 4px 20px;
        font-weight: 700;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border: 1px solid $brand-blue;
        background-color: #98c1f2;
        color: #ffffff;
        margin-right: 10px;
        cursor: pointer;


    }
    .active {
        background: #ffffff;
        color: $brand-blue;
        border-bottom: 1px solid #ffffff !important;
    }
}

.channel-data-container {
    background: #ffffff;
    padding: 10px
}

.merchandisers {
    padding-left: 10px;
    padding-right: 10px;
}

.merchandiser {
    display: flex;
    justify-content: space-between;

    ul {
        padding-left: 18px;
    }

    li {
        display: list-item;
    }
}

.merchandiser-images {
    width: 300px;
}

.merchandiser-image {
    @include background-image("merchandisers/merchadiser-background.jpg");
    background-size: cover;
    border: 1px solid $dark-gray;
    width: 300px;
    height: 450px;
    margin-bottom: 25px;
}

.merchandiser-title {
    color: $brand-blue;
    font-weight: 700;
    font-size: 20px;
}

.merchandiser-title--padded {
    color: $brand-blue;
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 25px;
}

.merchandiser-in-market-date {
    color: $dark-gray;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 5px;
}

.merchandiser-info {
    width: 380px;
    font-weight: 300;
    color: $dark-gray;
    margin-bottom: 25px;
}

.merchandiser-subheader {
    font-size: 16px;
    font-weight: 700;
    color: $dark-gray;
    padding-top: 25px;
    padding-bottom: 2px;
}

.merchandiser-option-title {
    font-size: 16px;
    font-weight: 700;
    color: $dark-gray;
    padding-bottom: 2px;
}

.merchandiser-item {
    font-size: 16px;
    font-weight: 700;
    color: #be1622;
    margin-bottom: 25px;
}

.merchandiser-recommended-brands {
    display: flex;
}

.merchandiser-recommended-brand {
    display: flex;
    font-size: 12px;
    margin-right: 15px;

    img {
        max-height: 60px;
        margin-right: 10px;
    }
    &:last-child {
        margin-right: 0px;
    }
}


.merchandiser-resources {
 display: flex;
}

.merchandiser-divider {

    border-top: solid 2px $dark-gray;
    padding-bottom: 25px;
}

.merchandiser-resource__coke-catalog {
    img {
        max-width: 70px;
    }
    margin-right: 15px;
}

.merchandiser-resource__ppt-download {
    flex-shrink: 0;
    width: 145px;
    height: 38px;
    @include background-image("icon-ppt.png");
    background-repeat: no-repeat;
    background-position: 12px 6px;
    background-size: 26px;
    margin-bottom: 10px;

    a {
        display: block;
        margin-top: 5px;
        margin-left: 45px;
        color: $brand-blue;
        font-weight: 300;
        font-size: 14px;
    }

    a:hover {
        text-decoration: underline;
    }
}

.merchandiser-resource__pdf-download {
    flex-shrink: 0;
    width: 145px;
    height: 38px;
    @include background-image("icon-pdf.png");
    background-repeat: no-repeat;
    background-position: 12px 6px;
    background-size: 26px;
    margin-bottom: 10px;

    a {
        display: block;
        margin-top: 12px;
        margin-left: 45px;
        color: $brand-blue;
        font-weight: 300;
        font-size: 14px;
    }

    a:hover {
        text-decoration: underline;
    }
}

.image-set__title {
    font-size: 14px;
    font-weight: 700;
    color: $dark-gray;
    border-bottom: 1px solid $dark-gray;
    margin-top: 25px;
    padding-bottom: 2px;
    margin-bottom: 10px;
    text-align: center;
    position: relative;

    .image-set__set-number {
        position: absolute;
        font-weight: 300;
        font-size: 12px;
        right: 0;
        top: 5px;
    }
}

.image-set {
    display: flex;
    flex-wrap: wrap;
}

.key-visual  {
    display: flex;
    flex-direction: column;
    width: 160px;
    margin-bottom: 20px;
    margin-right: 25px;

    &:nth-child(4n) {
        margin-right: 0px
    }
}

.key-visual__label {
    font-weight: 300;
    font-size: 12px;
    color: $brand-blue;
    text-align: right;
}
