.masthead {
    width: 960px;
    height: 90px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.masthead__site-title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: auto;

    img {
        width: 120px;
    }

    h1 {
        color: $brand-blue;
        font-size: 24px;
        font-weight: 700;
        padding-left: 10px;
        padding-bottom: 12px;
    }
}

.masthead__nav {
    font-weight: 700;
    font-size: 14px;
    color: $dark-gray;
    line-height: 14px;
    display: flex;
    align-items: baseline;
    margin-top: auto;

    ul {
        width: 500px;
        height: 45px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;

        :last-child {
            border-right: none;
        }
    }

    li {
        flex: auto;
        height: 30px;
        text-align: center;
        border-right: 1px solid $light-gray;
    }
}

.primary-nav__link {
    display: inline-block;
    height: 45px;
    text-decoration: none;
    & :hover {
        color: $brand-blue;
    }
}

.primary-nav__link--active {
    color: $brand-blue;
    border-bottom: 5px $brand-blue solid;
}

.content {
    border-top: 1px $brand-blue solid;
}