@mixin bp($min:0, $max:0) {
    $type: type-of($min);

    @if $type == string {
        $query: "";
        @if map-has-key($breakpoints, $min) {
            $query: append($query, "(min-width: #{map-get($breakpoints, $min)})");
        }
        @if map-has-key($breakpoints, $min) and map-has-key($breakpoints, $max) {
            $query: append($query, "and");
        }
        @if map-has-key($breakpoints, $max) {
            $query: append($query, "(max-width: #{map-get($breakpoints, $max)})");
        }
        @media screen and #{$query} {
            @content;
        }
    }

    @else if $type == number {
        $query: "";
        @if $min != 0 {
            $query: append($query, "(min-width: #{$min})");
        }
        @if $min != 0 and $max != 0 {
            $query: append($query, "and");
        }
        @if $max != 0 {
            $query: append($query, "(max-width: #{$max})");
        }
        @media screen and #{$query} {
            @content;
        }
    }
}