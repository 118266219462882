.summary__subcategories {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.summary__subcategories--align-left {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.subcategory {
    display: flex;
    flex-direction: column;
    flex-shrink:0;
    align-items: center;
    text-align: center;
    max-width: 94px;

    p {
        display: block;
        text-align: center;
    }

    img {
        flex-shrink:0;
    }
}


.summary__details {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.summary__shopper, .summary__occasion {
    margin-right: 10px;
}

.summary__shopper, .summary__occasion, .summary__trip {
    margin-top: 15px;
    flex: auto;
    display: flex;
    flex-direction: column;

    img {
        flex: 0;
    }
}

.shopper {
    max-width: 72px;

    img {
        max-width: 60px;
    }
}

.occasions {
    display: flex;
    flex-direction: row;

    .occasion {
        margin-right: 5px;
    }
}

.trips {
    display: flex;
    flex-direction: row;

    .trip {
        max-width: 60px;
        margin-right: 5px;
    }
}

.recommended-channels {
    display: flex;
    justify-content: center;
    margin-top: 15px;

    .channel {
        display: flex;
        flex-direction: column;
        max-width: 60px;
        margin-right: 5px;
    }

}
.summary-icon {
    max-width: 60px;
    max-height: 52px;
}

.summary-item-label {
    font-size: 11px;
    font-weight: 700;
    text-align: center;
}
