@mixin generateColumns($breakpoint, $maxDivisor) {
    @for $a from 1 through $maxDivisor {
        @for $b from 1 through $a {
            .col-#{$breakpoint}-#{$b}-#{$a} {
                width: ($b/$a) * 100%;
            }

            .col-#{$breakpoint}-offset-#{$b}-#{$a} {
                margin-left: ($b/$a) * 100%;
            }

        }
    }
}