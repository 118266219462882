.channels {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.channel {
    margin-right: 40px;
}

.approved-brands {
    display: flex;
}

.brand {
    padding-right: 15px;

    img {
        max-height: 115px;
    }
}