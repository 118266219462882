.footer {
    height: 100px;
    background-color: #4a4a4a;
}

.footer__content {
    width: 960px;
    margin: auto;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.footer__logo {
    img {
        width: 165px;
    }
}

.footer__copyright {
    font-size: 11px;
    color: #9a9a9a
}