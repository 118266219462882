.home-content__weather-container {
    position: absolute;
    top: 50px;
    right: 160px;
    width: 205px;
    height: 215px;
    background-color: rgba(255,255,255,.7);
    border-radius: 4px 4px 5px 5px;
    box-shadow:0 0 15px rgba(#000, 0.3);
}

.weather-widget {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.weather-widget__reset {
    height: 25px;
    text-align: right;
    box-sizing: content-box;
    padding: 4px;

    img {
        width: 18px;
        height: 20px;
    }
}

.weather-widget__condition-graphic {
    flex: 1;
    display: flex;
    text-align: center;
    align-content: center;
    justify-content: center;

    img {
        flex-shrink: 0;
        flex-grow: 0;
        align-self: center;
        width: 80px;
        height: 80px;
    }
}

.weather-widget__current-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    height: 36px;
}

.weather-widget__current-temp {
    color: #7b8082;
    font-size: 32px;
    font-weight: 300;
    padding-left: 10px;
}

.weather-widget__current-weather {
    color: #7b8082;
    font-size: 14px;
    font-weight: 500;
}

.current-location {
    color: #7b8082;
    font-size: 11px;
    font-weight: 300;
}

.weather-widget__current-date {
    color: #ffffff;
    background-color: #54bae6;
    display: flex;
    flex-direction: column;
    width: 35px;
    align-items: center;
    padding-top: 3px;
}

.current-month {
    font-size: 12px;
    height: 12px;
    font-weight: 700;
}

.current-day {
    font-size: 16px;
    font-weight: 700;
}

.weather-widget__footer {
    background-color: #2e3335;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #ffffff;
    font-size: 11px;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;

    img {
        padding-right: 5px;
    }
}

.footer-item {
    display: flex;
    flex-direction: row;
}

.footer-data {
    min-width: 25px;
}

.weather-widget__air-pressure-icon {

}

.weather-widget__wind-icon {

    width: 16px;
    height: 13px;
}

.weather-widget__humidity-icon {

}