.review-instructions {
    padding: 20px;
    background-color: rgba(255,255,255,0.7);
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    justify-content: space-between;

    h2.instructions-header {
        font-size: 20px;
        text-align: center;
        width: 100%;
        margin-bottom: 15px;
    }
}

.pdf-header {
    padding-bottom: 15px;

    img {
        width: 920px;
        align-self: center;
    }
}

.review-pdf-title {
    width: 100%;
    text-align: center;
    font-size: 28px;
    color: $brand-blue;
}

.customer-data {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.customer-team {
    width: 65%;

    input {
        width: 100%;
    }
}

.customer-date {
    width: 30%;

    input {
        width: 100%;
    }
}

.customer-task {
    padding-top: 15px;


    input {
        width: 100%;
    }
}

textarea {
    resize: none;
}

.review-merchandiser {
    width: 310px;
    height: 440px;
    overflow: hidden;
    margin-bottom: 15px;
}

.review-block {
    padding: 20px;
    background-color: rgba(255,255,255,0.7);
    border-top: 1px solid $brand-blue;
    margin-bottom: 15px;
}
.review-block-last {
    padding: 20px;
    background-color: rgba(255,255,255,0.7);
    border-top: 1px solid $brand-blue;
}

.review-block--small {
    width: 310px;
}

.review-block--large {
    width: 635px;
}

.review-block--full {
    width: 100%;
}

.review-block__title {
    color: $brand-blue;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
}

label {
    color: $brand-blue;
    font-size: 12px;
}

.review-subheader {
    font-size: 16px;
    font-weight: 700;
    color: $dark-gray;
    border-bottom: 1px solid $dark-gray;
    padding-bottom: 2px;
    margin-bottom: 10px;
    text-align: center;
}

.review-input {
    border: 1px solid $brand-blue;
    border-radius: 4px;
    padding: 4px;
    background-color: #ffffff;
    font-family: $default-font;
    color: $dark-gray;
    font-size: 16px;
    font-weight: 700;
}

.review-overview {
    display: flex;
    flex-wrap: wrap;

    .summary-item-label {
        color: $dark-gray;
    }
}

.review-overview__divider {
    display: block;
    width: 100%;
    padding-bottom: 50px;
}

.review-overview__statement {
    color: $brand-blue;
    font-weight: 300;
    font-style: italic;
    font-size: 24px;
    width: 335px;
}

.review-overview__approved-brands {
    width: 225px;
}

.review-overview__shopper {
    margin-right: 25px;
}

.review-overview__occasions {
    margin-right: 25px;
}

.review-overview__trip {
    margin-right: 25px;
}

.review-channels {
    display: flex;

    .review-channel {
        margin-right: 25px;
    }
}

.review-key-visuals {
    display: flex;
    justify-content: space-between;
}

#review-digital-platform {
    max-height: 275px;
    margin: auto;
}

#agency-task {

}

.agencies {
    display: flex;
    flex-direction: column;
}

.agency {
    display: flex;
    margin-bottom: 25px;
}

.agency__image {
    width: 140px;
    flex-grow: 0;
    text-align: center;
    img {
        max-height: 80px;
    }

    margin-right: 15px;
}

.agency__info {
    color: $dark-gray;
    font-weight: 300;
    font-size: 14px;
    margin-right: 25px;

    strong {
        font-weight: 700;
    }

    &:last-child {
        margin-right: 0px;
    }
}

.review-blue-header {
    color: $brand-blue;
}

.download-pdf {
    display: block;
    width: 225px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 5px;
    @include background-image("icon-pdf-alt.png");
    background-repeat: no-repeat;
    background-position: 6px 6px;
    background-size: 25px 28px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;

    a {
        display: block;
        padding-top: 10px;
        margin-left: 36px;
        color: $brand-blue;
        font-weight: 300;
        font-size: 14px;
        text-decoration: underline;
    }

    a:hover {
        text-decoration: underline;
    }
}

.download-hires-images {
    display: flex;
    color: $brand-blue;
    font-weight: 300;
    font-size: 20px;
    align-items: center;

    img {
        max-height: 40px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.review-key-visual  {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-right: 25px;

    img {
        max-height: 160px;
    }

    &:nth-child(4n) {
        margin-right: 0px
    }
}

.key-visual__label {
    font-weight: 300;
    font-size: 12px;
    color: $brand-blue;
    text-align: right;
}