@import "generate-columns";
$maxDivisor: 6 !default;

.row {
    margin-left:-$gutter/2;
    margin-right:-$gutter/2;
    display:flex;
    flex-wrap: wrap;
}

[class*="col-"], .col {
    padding:0 $gutter/2;
    width:100%;
}

.col {
    width:100%;
}

.col--flex {
    display:flex;
    flex-direction:column;
}

// By Default, we only generate breakpoint specific classes for small, medium, and large
// breakpoints. You can follow the pattern below to create additional classes if necessary.

// Generate classes for small breakpoint
@media  screen and (min-width:$bp-small) {
    @include generateColumns(small, $maxDivisor);
}

// Generate classes for medium breakpoint
@media  screen and (min-width:$bp-medium) {
    @include generateColumns(medium, $maxDivisor);
}

// Generate classes for large breakpoint
@media  screen and (min-width:$bp-large) {
    @include generateColumns(large, $maxDivisor);
}
