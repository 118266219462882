.solutions-main-content {
    width: 745px;
    background-color: #ffffff;
    padding: 5px;
}

.solutions-main-content__masthead {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;

    h1 {
        color: $brand-blue;
        font-weight: 300;
        font-size: 20px;
        line-height: 34px;
    }
}

.execution-calendar {
    flex-shrink: 0;
    width: 210px;
    height: 40px;
    background-color: #e4e4e4;
    @include background-image("icon-calendar.png");
    background-repeat: no-repeat;
    background-position: 12px 6px;
    border-radius: 8px;

    a {
        display: block;
        margin-top: 12px;
        margin-left: 45px;
        color: $brand-blue;
        font-weight: 300;
        font-size: 14px;
    }

    a:hover {
        text-decoration: underline;
    }
}

.solutions-nav {
    width: 204px;
    margin-right: 12px;

    img {
        display: block;
    }
}

.nav-block {
    margin-bottom: 2px;
}

.solutions-nav__sublink {
    height: 35px;
    box-sizing: border-box;
    display: block;
    color: $dark-gray;
    background-color: #d8d8d8;
    border-bottom: 1px solid #b7b7b7;
    padding-left: 50px;
    padding-top: 7px;
    @include background-image("buttons/nav-radio-off.png");
    background-repeat: no-repeat;
    background-position: 15px 7px;
    background-size: 23px 23px;

    &:hover {
        @include background-image("buttons/nav-radio-on.png");
    }
}

.solutions-nav__sublink-inactive {
    height: 35px;
    box-sizing: border-box;
    display: block;
    color: $light-gray;
    background-color: #d8d8d8;
    border-bottom: 1px solid #b7b7b7;
    padding-left: 50px;
    padding-top: 5px;
    line-height: 12px;
    @include background-image("buttons/nav-radio-off.png");
    background-repeat: no-repeat;
    background-position: 15px 7px;
    background-size: 23px 23px;
    cursor: default;
}

.solutions-nav__sublink--stacked {
    padding-top: 5px;
    line-height: 12px;
}

.solution-content {
    width: 745px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
}

.solution-content__summary {
    flex: 1;
    padding: 10px;
    color: $dark-gray;

    h1 {
        color: $brand-blue;
        font-weight: 300;
        font-style: italic;
        font-size: 26px;
        line-height: 30px;
        padding-bottom: 25px;
    }

    h2 {
        color: $dark-gray;
        font-weight: 300;
        font-size: 22px;
        line-height: 26px;
        padding-bottom: 5px;
    }

    h3 {
        color: $brand-blue;
        font-weight: 700;
        font-size: 30px;
        line-height: 32px;
        padding-bottom: 25px;
        text-align: center;
    }

    ul {
        padding-left: 18px;
    }

    li {
        display: list-item;
    }
}

.solutions-main-content__corridors {
    display: flex;

    h2 {
        display: block;
        color: $brand-blue;
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .health-wellness {
        display: flex;
        flex-direction: column;

    }

    .active-lifestyles {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
    }

    .corridor-images {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        img {
            max-width: 172px;
            height: auto;
            margin-right: 2px;
        }
    }

    .corridor-images--left {
        border-right: 1px solid #666666;
        padding-right: 14px;
    }

    p {
        color: $brand-blue;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 30px;
    }
}

.solution-content__hero-image {
    width: 312px;

    img {
        display: block;
    }
}

.solution-content__hero-image--bordered {
    width: 312px;

    img {
        display: block;
        border-bottom: solid 6px $brand-blue;
    }
}


.solution-content__build-custom {
    font-size: 13px;
    color: $brand-aqua;
    font-style: italic;
    font-weight: 300;
    margin-bottom: 25px;
    margin-top: 60px;
    text-align: center;

    a {
        display: inline-block;
        margin-bottom: 15px;
    }

    img {
        width: 220px;
        border: none;
    }
}

.solution__section-subheader {
    font-size: 16px;
    font-weight: 700;
    color: $dark-gray;
    border-bottom: 1px solid $dark-gray;
    padding-top: 25px;
    padding-bottom: 2px;
    margin-bottom: 10px;
    text-align: center;

    &:first-of-type {
        padding-top: 5px;
    }
}