.resources-container {
    border-top: 1px $brand-blue solid;
    width: 744px;
    background-color: #ffffff;
    margin-right: 10px;
}

.resources__masthead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.resources__header {
    color: $brand-blue;
    font-size: 36px;
    font-weight: 300;
}

.resources__subheader {
    color: $brand-aqua;
    font-size: 18px;
    font-weight: 300;
    padding-top: 10px;
    padding-bottom: 5px;
}

.resources__search {
    padding-top: 5px;
    padding-bottom: 5px;
}

.search-input {
    width: 300px;
    border: 1px solid $brand-blue;
    border-radius: 3px;
    padding: 5px;
    font-family: proxima-nova, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #4a4a4a;
}

.resources__table {
    padding: 0 20px 20px 20px;

}

.resource {
    display:flex;
    font-weight: 300;
    align-items: center;
    color: #adadad;
    border-top: 1px solid #f0ede4;
    padding-top: 6px;
    padding-bottom: 6px;

    a {
        display:block;
    }
    a:hover {
        color: #9b9b9b;
        text-decoration: underline;
    }
}

.resource__primary {
    display: block;
    flex-grow:1;
}

.resource__icon {
    width:25px;
    height:25px;
    flex-shrink:0;
    background-position:left center;
    background-size:auto 25px;
    background-repeat:no-repeat;
    margin-right: 15px;
}

.resource__icon--pdf {
    @include background-image('icon-pdf.png');
}
.resource__icon--ppt {
    @include background-image('icon-ppt.png');
}
.resource__icon--video {
    @include background-image('icon-video.png');
}
.resource__icon--xls {
    @include background-image('icon-xls.png');
}
.resource__icon--link {
    @include background-image('icon-link.png');
}
.resource__icon--doc {
    @include background-image('icon-doc.gif');
}

.contacts-container {
    border-top: 1px $brand-blue solid;
    width: 205px;
    box-sizing: border-box;
    background-color: #ffffff;
    padding-left: 8px;
    padding-right: 8px;
}

.contacts__header {
    color: $brand-blue;
    font-size: 36px;
    font-weight: 300;
}

.contact {
    border-top: 1px solid #f0ede4;
    padding-top: 4px;
    padding-bottom: 8px;
    color: $dark-gray;
}

.contact__name {
    font-weight: 700;
    font-size: 14px;
    padding-left: 5px;
}

.contact__title, .contact__email {
    font-weight: 300;
    font-size: 12px;
    padding-left: 5px;
    color: #8a8a8a;

    a {
        display:block;
    }
    a:hover {
        color: #9b9b9b;
        text-decoration: underline;
    }
}
