.build-content-wrapper {
    width: 960px;
    margin: 40px auto;
    display: flex;
    flex-direction: column;

    h3 {
        font-size: 20px;
        font-weight: 700;
        color: $brand-blue;
    }
}

.progress-indicator {
    width: 960px;
    height: 36px;
    margin-top: 10px;
    margin-bottom: 10px;

    ul {
        display: flex;
        flex-direction: row;

        li:not(:first-child) {
            margin-left: -10px;
        }
    }
}

.build-content {
    h1 {
        color: $brand-blue;
        font-weight: 300;
        font-size: 24px;
    }
}

.build-content__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    background-color: rgba(255,255,255,0.7);
}

.nav__next, .nav__back {
    img {
        width: 84px;
        height: 37px;
    }
}

.step {
    min-height: 450px;
    padding: 20px;
    background-color: rgba(255,255,255,0.7);

    h2 {
        color: $brand-blue;
        font-size: 28px;
        font-weight: 300;
    }
}

#step2, #step5 {
    padding-left: 0px;
    padding-right: 0px;
}

#step6 {
    padding: 0px;
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.step1__corridors {
    width: 745px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;

    a {
        max-width: 236px;
        margin-right: 4px;
    }
}

.step1__corridor-titles {
    width: 745px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
}

.corridor-title {
    display: block;
    color: $brand-blue;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
    width: 50%;
    margin-bottom: 15px;
}

.step3__subcategories {
    padding-top: 20px;
    width: 852px;
    margin: auto;
    text-align: center;

    a {
        display: inline-block;
        width: 198px;
        height: 234px;
        margin-right: 15px;

        &:last-of-type{
            margin-right: 0px;
        }
    }
}

.subcategories-header {
    width: 852px;
    margin: auto;
}

.visual-guideline {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
}

.visual-guideline__content {
    width: 190px;
    color: $dark-gray;
    font-size: 16px;
    font-weight: 300;

    strong {
        font-weight: 700;
    }
}

.download-guidelines {
    display: block;
    width: 200px;
    height: 40px;
    @include background-image("icon-pdf-alt.png");
    background-repeat: no-repeat;
    background-position: 6px 6px;
    background-size: 25px 28px;
    margin-bottom: 15px;

    a {
        display: block;
        padding-top: 6px;
        margin-left: 36px;
        color: $brand-blue;
        font-weight: 300;
        font-size: 14px;
        text-decoration: underline;
    }

    a:hover {
        text-decoration: underline;
    }
}

.visual-guideline__final-art {
    flex-shrink: 0;
    width: 400px;

    img {
        margin-bottom: 15px;
    }
}

.visual-guideline__merchandiser {
    flex-shrink: 0;
    width: 300px;
}

.custom-key-visuals {
    display: flex;
    justify-content: space-between;
}

h2.custom-keyvisuals-header {
    color: $brand-blue;
    font-weight: 300;
    font-size: 20px;
    text-align: center;
}

.custom-key-visuals-merchandiser {
    width: 300px;
}

.custom-key-visuals-coming-soon {
    text-align: center;
    img {
        max-width: 400px;
        margin: auto;
    }
}

.custom-key-visuals-merchandiser__title {
    font-size: 14px;
    font-weight: 700;
    color: $dark-gray;
    border-bottom: 1px solid $dark-gray;
    margin-top: 25px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    text-align: center;
    position: relative;
}

.custom-key-visual-info {
    display: flex;
    justify-content: space-between;

    .key-visual__label {
        padding-top: 3px;
    }
}

.custom-key-visual-select-btn {
    width: 80px;
    cursor: pointer;
    align-self: center;
    margin-top: -5px;
}

.key-visuals, .taglines {
    width: 870px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.selection-subheader {
    font-size: 16px;
    font-weight: 700;
    color: $dark-gray;
    border-bottom: 1px solid $dark-gray;
    padding-top: 10px;
    padding-bottom: 2px;
    margin-bottom: 10px;
    text-align: center;
}

.subcategory-selection {
    text-align: left;
}

.key-visual-selection {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 25px;

    img {
        width: 80px;
    }
}

.tagline__selection {
    display: inline-block;
    margin-bottom: 25px;
    width: 250px;

    a {
        display: block;
        padding-bottom: 20px;
    }
}

.tagline__merchandiser {
    width: 300px;
}

.select-btn{
    padding-top: 5px;
    width: 80px;
}

.existing-solutions-link {
    width: 420px;
}

h2.pre-post-header {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
}

.pre-post-section {
    width: 930px;
    display: flex;
    justify-content: space-between;
}

.pre-post-channel {
    width: 120px;
}

.pre-post-content {
    width: 780px;
    flex-wrap: wrap;
    border-top: 1px solid $brand-blue;
    display: flex;
    padding-top: 25px;
}

.pre-post-content--centered {
    justify-content: center;
}

.content-column {
    width: 260px;
}

.pre-post-content__divider {
    display: block;
    width: 780px;
    flex-shrink: 0;
    padding-bottom: 40px;
}

.pre-post-content__images {
    width: 495px;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;

    img {
        max-width: 465px;
        max-height: 290px;
    }
}

.pre-post-content__copy {
    color: $dark-gray;
    width: 280px;
    font-weight: 300;

    strong {
        font-weight: 700;
    }

    p {
        margin-bottom: 15px;
    }

    img {
        max-height: 250px;
    }
}

.pre-post-content__copy-callout {
    color: $brand-blue;
    background-color: #ffffff;
    font-weight: 700;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
    width: 95%;
}

.custom-image-sets {
    width: 600px;
    flex: 0;
}

.custom-image-set {
    width: 600px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.custom-key-visual  {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-right: 25px;

    img {
        padding: 3px;
    }

    &:nth-child(2n) {
        img {
            padding: 0px;
            border: 3px solid $brand-blue;
        }
    }

    &:nth-child(3n) {
        margin-right: 0px;
    }
}

.custom-key-visual__label {
    font-weight: 300;
    font-size: 12px;
    color: $brand-blue;
    text-align: right;
}

.image-set-info {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border-bottom: 1px solid $dark-gray;
    margin-top: 25px;
    padding-bottom: 2px;
    margin-bottom: 10px;
    color: $brand-blue;
}

.custom-image-set__title {
    font-size: 14px;
    font-weight: 700;
    color: $dark-gray;
    text-align: center;
}
.custom-image-set__set-number {
    font-weight: 300;
    font-size: 12px;
    color: $brand-blue;
}