// Proxima Nova
@font-face {
    font-family: 'proxima-nova';
    src: url('#{$fontPath}proximanova-light-webfont.eot');
    src: url('#{$fontPath}proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}proximanova-light-webfont.woff') format('woff'),
    url('#{$fontPath}proximanova-light-webfont.ttf') format('truetype'),
    url('#{$fontPath}proximanova-light-webfont.svg#proxima_novalight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('#{$fontPath}proximanova-lightitalic-webfont.eot');
    src: url('#{$fontPath}proximanova-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}proximanova-lightitalic-webfont.woff') format('woff'),
    url('#{$fontPath}proximanova-lightitalic-webfont.ttf') format('truetype'),
    url('#{$fontPath}proximanova-lightitalic-webfont.svg#proxima_novalight_italic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('#{$fontPath}proximanova-regular-webfont.eot');
    src: url('#{$fontPath}proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}proximanova-regular-webfont.woff') format('woff'),
    url('#{$fontPath}proximanova-regular-webfont.ttf') format('truetype'),
    url('#{$fontPath}proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('#{$fontPath}proximanova-regularitalic-webfont.eot');
    src: url('#{$fontPath}proximanova-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}proximanova-regularitalic-webfont.woff') format('woff'),
    url('#{$fontPath}proximanova-regularitalic-webfont.ttf') format('truetype'),
    url('#{$fontPath}proximanova-regularitalic-webfont.svg#proxima_novaregular_italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('#{$fontPath}proximanova-semibold-webfont.eot');
    src: url('#{$fontPath}proximanova-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}proximanova-semibold-webfont.woff') format('woff'),
    url('#{$fontPath}proximanova-semibold-webfont.ttf') format('truetype'),
    url('#{$fontPath}proximanova-semibold-webfont.svg#proxima_novasemibold') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('#{$fontPath}proximanova-semibolditalic-webfont.eot');
    src: url('#{$fontPath}proximanova-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}proximanova-semibolditalic-webfont.woff') format('woff'),
    url('#{$fontPath}proximanova-semibolditalic-webfont.ttf') format('truetype'),
    url('#{$fontPath}proximanova-semibolditalic-webfont.svg#proxima_novasemibold_italic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('#{$fontPath}proximanova-bold-webfont.eot');
    src: url('#{$fontPath}proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}proximanova-bold-webfont.woff') format('woff'),
    url('#{$fontPath}proximanova-bold-webfont.ttf') format('truetype'),
    url('#{$fontPath}proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('#{$fontPath}proximanova-bolditalic-webfont.eot');
    src: url('#{$fontPath}proximanova-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}proximanova-bolditalic-webfont.woff') format('woff'),
    url('#{$fontPath}proximanova-bolditalic-webfont.ttf') format('truetype'),
    url('#{$fontPath}proximanova-bolditalic-webfont.svg#proxima_nova_rgbold_italic') format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('#{$fontPath}proximanova-extrabold-webfont.eot');
    src: url('#{$fontPath}proximanova-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}proximanova-extrabold-webfont.woff') format('woff'),
    url('#{$fontPath}proximanova-extrabold-webfont.ttf') format('truetype'),
    url('#{$fontPath}proximanova-extrabold-webfont.svg#proxima_novaextrabold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('#{$fontPath}proximanova-extrabolditalic-webfont.eot');
    src: url('#{$fontPath}proximanova-extrabolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}proximanova-extrabolditalic-webfont.woff') format('woff'),
    url('#{$fontPath}proximanova-extrabolditalic-webfont.ttf') format('truetype'),
    url('#{$fontPath}proximanova-extrabolditalic-webfont.svg#proxima_novaextrabold_italic') format('svg');
    font-weight: 800;
    font-style: italic;
}

$proximanova: proxima-nova, Helvetica, Arial, sans-serif;

$default-font: $proximanova;