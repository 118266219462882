.content {
    border-top: 1px $brand-blue solid;
    position: relative;
}

.slide-show {
    height: 835px;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.home-content__copy {
    position: absolute;
    top: 50px;
    left: 160px;
    color: #ffffff;

    p {
        font-size: 36px;
        font-weight: 700;
    }

    ul {
        margin-bottom: 24px;
    }

    li {
        font-size: 28px;
        font-weight: 300;
    }

    li:before {
        content: "\2022\020"
    }
}

.home-content__link {
    font-size: 18px;
    font-weight: 300;
    background: rgba(152,194,242,0.6);
    border: 1px solid #ffffff;
    margin-right: 10px;
    display: inline-block;
    width: 260px;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;

    &:hover {
        background: rgba(47,106,175,0.7);
    }
}
.execution-guidelines-container {
    position: absolute;
    top: 275px;
    left: 0px;
    overflow: hidden;
}

.execution-guidelines {
    width: 777px;
    height: 300px;
    @include background-image("execution-guidelines/execution-guidelines-background.png");
    background-repeat: no-repeat;
}

.execution-guidelines__circles {
    padding-top: 15px;
    padding-left: 5px;

    img {
        width: 290px;
        height: 252px;
    }
}

.execution-guidelines__button {
    position: absolute;
    top: 12px;
    right: 10px;
    display: block;
}
.execution-guidelines__text {
    position: absolute;
    top: 55px;
    margin-left: 310px;
    color: #ffffff;
}

.execution-guidelines__headline {
    font-size: 50px;
    font-weight: 300;
}

.execution-guidelines__copy {
    font-size: 32px;
    font-weight: 700;
    line-height: 30px;
}

.execution-guidelines__learn-more {
    @include background-image("execution-guidelines/icon-learn-more.png");
    background-size: 30px 30px;
    background-repeat: no-repeat;
    display: inline-block;
    text-decoration: underline;
    font-size: 16px;
    font-weight: normal;
    height: 40px;
    margin-top: 30px;
    margin-right: 50px;
    padding-top: 7px;
    padding-left: 38px;
}

.execution-guidelines__download {
    @include background-image("execution-guidelines/icon-download.png");
    background-size: 21px 30px;
    background-repeat: no-repeat;
    display: inline-block;
    text-decoration: underline;
    font-size: 16px;
    font-weight: normal;
    height: 40px;
    margin-top: 30px;
    padding-top: 7px;
    padding-left: 32px;
}
.execution-guidelines__button {
    img {
        width: 15px;
        height: 26px;
    }
}

.button-closed {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(180deg);
}

.logos-container {
    position: absolute;
    bottom: 150px;
    left: 160px;
}

.logos {
    width: 960px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img {
        flex: 0;
        align-self: center;
        opacity: 0.7;
        filter: alpha(opacity=70);
    }
}