.point-of-sales-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.point-of-sale {
    border: 1px solid #979797;
    width: 222px;
    height: 300px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 10px;
}

.point-of-sale__image {
    height: 235px;
    padding: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        display: flex;
        height: 100%;
        margin: auto;
    }
}

.point-of-sale__footer {
    background-color: #ffffff;
    padding-top: 15px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    justify-content: space-between;
}

.point-of-sale-title {
    color: $brand-blue;
    font-weight: 700;
    font-size: 18px;
}


.point-of-sale-logo {

}
