.why-hydration-index {
    flex: 1;
    padding-top: 10px;

    a {
        display: block;
        color: #4a4a4a;
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 20px;
    }

    a:hover {
        text-decoration: underline;
    }
}

.why-hydration-wrapper {
    flex: 0;
    flex-basis: 745px;
    width: 745px;
}

.video-container {
    width: 745px;
    background-color: #ffffff;
    padding: 12px 13px 12px 12px;
    box-sizing: border-box;

    @include background-image("why-hydration/first-section-background.jpg");
    background-repeat: repeat-x;

    section {
        padding: 8px 7px 8px 8px;

        h1 {
            color: $brand-blue;
            font-weight: 300;
            font-size: 36px;
        }
    }
}

.opportunity-content {
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    margin-bottom: 10px;

    p {
        color: $dark-gray;
        font-weight: 300;
        max-width: 230px;
    }

    img {
        max-width: 382px;
        padding-right: 20px;
        padding-bottom: 20px;
        align-self: center;
    }
}

.opportunity-content-two {
    display: flex;
    padding-top: 20px;

    .opportunity-content-two__copy {
        color: $dark-gray;
        font-weight: 300;
        max-width: 300px;
        padding-top: 20px;
        padding-left: 50px;
    }

    .opportunity-content-two__image {
        max-width: 315px;
        margin-left: 20px;

    }

    .bold-text {
        font-weight: 700;
    }
}

.why-hydration-content {

    section {
        @include background-image("why-hydration/section-background.jpg");
        background-repeat: repeat-x;
        padding: 20px;
        font-weight: 300;
        h1 {
            color: $brand-blue;
            font-weight: 300;
            font-size: 36px;
        }
    }
}

.target-consumers-content {
    display: flex;
    color: $dark-gray;
    font-weight: 300;
    margin-top: 10px;

    .cbl-logo {
        max-width: 100px;
        margin-bottom: 20px;
    }

    .download-content {
        width: 230px;
    }

    table {
        width: 400px;
        margin-left: 30px;

        img {
            max-width: 90px;
        }

        .cohorts {
            padding-left: 5px;

            img {
                width: 70px;
                flex-shrink: 0;
                margin-left: 5px;
            }
        }
    }
}
.target-consumers-download {
    background-color: #ffffff;
    padding: 10px;
    margin-top: 25px;
    @include background-image("icon-ppt.png");
    background-repeat: no-repeat;
    background-position: 12px 6px;
    background-size: 20px 25px;
    border-radius: 8px;
    width: 200px;

    a {
        display: block;
        margin-left: 35px;
        color: $brand-blue;
        text-decoration: underline;
        font-weight: 300;
        font-size: 14px;
    }
}

.target-consumers-content-two {
    display: flex;
    padding-top: 20px;
}

.blue-line {
    margin-top: 10px;
    border-top: 1px solid $brand-blue;
    padding-bottom: 10px;
}

.blue-line--halfpadding {
    border-top: 1px solid $brand-blue;
    margin-top: 5px;
    padding-bottom: 5px;
}

.table-label {
    font-size: 16px;
    font-weight: 700;
}

.table-tagline {
    font-style: italic;
    font-weight: 500;
}

.table-text-center {
    text-align: center;
}

.table-text-bold {
    font-weight: 700;
}

#strategic-corridors {
    color: $dark-gray;

    table {
        font-size: 14px;
        line-height: 16px;

        td {
            vertical-align: top;
            padding: 2px;
        }
    }
}

.presentation-download {
    flex-shrink: 0;
    width: 210px;
    height: 40px;
    background-color: rgba(255,255,255,.6);
    @include background-image("icon-ppt.png");
    background-repeat: no-repeat;
    background-position: 12px 6px;
    background-size: 22px 28px;
    border-radius: 8px;
    margin-top: 12px;

    a {
        display: block;
        padding-top: 12px;
        margin-left: 45px;
        color: $brand-blue;
        font-weight: 300;
        font-size: 14px;
    }

    a:hover {
        text-decoration: underline;
    }
}