@mixin linear-gradient($angle, $color-stops...) {
    $_angle-with-vendor-prefix: "";
    $_angle: "";
    @if $angle == "to top" or $angle == "bottom" {
        $_angle-with-vendor-prefix: bottom;
        $_angle: to top;
    } @else if $angle == "to right" or $angle == "left" {
        $_angle-with-vendor-prefix: left;
        $_angle: to right;
    } @else if $angle == "to bottom" or $angle == "top" {
        $_angle-with-vendor-prefix: top;
        $_angle: to bottom;
    } @else if $angle == "to left" or $angle == "right" {
        $_angle-with-vendor-prefix: right;
        $_angle: to left;
    } @else if $angle == "to top right" or $angle == "bottom left" {
        $_angle-with-vendor-prefix: bottom left;
        $_angle: to top right;
    } @else if $angle == "to bottom right" or $angle == "top left" {
        $_angle-with-vendor-prefix: top left;
        $_angle: to bottom right;
    } @else if $angle == "to bottom left" or $angle == "top right" {
        $_angle-with-vendor-prefix: top right;
        $_angle: to bottom left;
    } @else if $angle == "to top left" or $angle == "bottom right" {
        $_angle-with-vendor-prefix: bottom right;
        $_angle: to top left;
    } @else {
        $_angle-with-vendor-prefix: $angle % 360;
        $_angle: (90 - $angle) % 360;
    }
    background-image: -webkit-linear-gradient($_angle-with-vendor-prefix, $color-stops);
    background-image: -moz-linear-gradient($_angle-with-vendor-prefix, $color-stops);
    background-image: -o-linear-gradient($_angle-with-vendor-prefix, $color-stops);
    background-image: linear-gradient($_angle, $color-stops);
}