.wrapper {
    padding-top:52px;
    width:1280px;
    margin:0 auto;
    box-shadow:0 0 30px rgba(#000, 0.3);
    background-color: #ffffff;

    @include bp($bp-large) {
        padding-top:0;
    }
}

.wrapper-background {
    @include background-image("background.jpg");
    background-repeat: repeat-y;
}

.home-content, .content {
    border-top: 1px $brand-blue solid;
    position: relative;
}

.inner-content {
     width: 960px;
     margin: 40px auto;
     display: flex;
     flex-direction: row;
 }

.inner-content--border {
    border-top: 1px $brand-blue solid;
}