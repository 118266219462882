html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
body {
    position:relative;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 16px;
    background:$body-bg;
    color:#1a1a1a;
    font-family:$default-font;
    font-weight:normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
figure,
ol,
ul,
code,
pre {
    margin: 0;
    padding: 0;
}
li {
    display: block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}
a {
    text-decoration: none;
    color: inherit;
}
img {
    max-width: 100%;
    height: auto;
    border: 0;
}

svg {
    display: block;
    width:100%;
}

pre {
    white-space: pre-wrap;       /* CSS 3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

*, :before, :after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

table {
    width:100%;
    border-collapse: collapse;
}

td, th {
    padding:0;
}

th {
    text-align:left;
    font-weight:normal;
}