.home-content__did-you-know-container {
    position: absolute;
    top: 275px;
    right: 160px;
    width: 205px;
    height: 165px;
    background-color: rgba(255,255,255,.7);
    border-radius: 4px;
    box-shadow:0 0 15px rgba(#000, 0.3);
    overflow: hidden;
}

.did-you-know-widget {
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
    }
}